import { Component } from 'react';
import { BlockInstanceProps } from '../Block';
import SideboxComponent from '../../components/Sidebox';
import { ContentTypeEnum } from '../../constants/cms-constants';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';

@ControlOption([
  {
    key: 'fontColor',
    definition: controlOptionDefinitions.fontColor,
  },
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class SideboxBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { header, bodyText } = block;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = controlOptions?.backgroundColor;
    const fontColor = controlOptions?.fontColor;

    let image;
    let video;
    let factBoxContent;
    blockContent.doQuery((content) => {
      image = content.getFirstOfType(ContentTypeEnum.IMAGE, false);
      video = content.getFirstOfType(ContentTypeEnum.VIDEO, false);
      factBoxContent = content.getFirstOfType(ContentTypeEnum.MEDIAITEM, false);
    });

    return (
      <SideboxComponent
        headerText={header?.main}
        text={bodyText}
        image={image}
        video={video}
        factBoxTitleText={factBoxContent?.header}
        factBoxBodyText={factBoxContent?.bodyText}
        factBoxFontColor={fontColor}
        factBoxBackgroundColor={backgroundColor}
      />
    );
  }
}

export default SideboxBlock;
