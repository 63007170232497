import { Component } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import TitleTextGroup from './TitleTextGroup';
import * as InternalPropTypes from '../../../constants/internal-types';
import {
  TypeFactBoxTypes,
  FactBoxEnum,
} from '../../../constants/cms-constants';
import TitleTypes from '../../../constants/title-types';

type FactBoxProps = {
  type?: TypeFactBoxTypes;
  titleText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  styling?: Interpolation<Theme>;
};

class FactBox extends Component<FactBoxProps> {
  static types = FactBoxEnum;

  static defaultProps = {
    type: this.types.CONTACT,
    titleText: null,
    bodyText: null,
    styling: null,
  };

  render() {
    const { type, titleText, bodyText, styling } = this.props;

    function getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    }

    const titleType = getKeyByValue(TitleTypes, type);

    return (
      <div css={styling}>
        <TitleTextGroup
          bodyText={bodyText}
          titleText={titleText}
          type={TitleTypes[titleType]}
        />
      </div>
    );
  }
}

export default FactBox;
