/* eslint-disable react/prop-types */

import { Component } from 'react';
import { css } from '@emotion/react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { compose } from 'recompose';

import logoWhite from '../assets/logo-white.png';
import logoBlack from '../assets/logo-black.png';

import Caption from './common/atoms/Caption';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import MediaQuery from './common/atoms/MediaQuery';
import NavListIndicator from './common/molecules/NavListIndicator';
import FoldButton from './common/atoms/FoldButton';
import withAppConfig, { WithAppConfigProps } from '../utils/hocs/withAppConfig';

import { colorEnum } from '../constants/colors';
import font from '../constants/fonts';
import * as Language from '../constants/language';
import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import { MenuColorEnum } from '../constants/cms-constants';

const useStyles = css({
  //
  //  following is style for placing elements, all breakpoints
  //
  position: 'relative',
  '& .navBar': {
    [Breakpoints.Mobile.mq]: {
      paddingTop: '1px',
      paddingLeft: '13px',
    },
    [Breakpoints.TabletUp.mq]: {
      paddingTop: '7px',
      paddingLeft: '21px',
    },
  },
  '& .navbar-brand img': {
    maxWidth: '135px',
    position: 'relative',
    zIndex: '1200',
    paddingBottom: '2px',
  },
  '& .linkNav': {
    textTransform: 'uppercase',
    '& .nav-item': {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '15px', // to get a bigger surface menu hover area
      marginBottom: '-15px', // to get a bigger surface menu hover area
      '& a': {
        textDecoration: 'none',
      },
    },
    '& .active span': {
      cursor: 'pointer',
    },
  },
  '& .langNav': {
    '.currentLanguage': {
      textDecoration: 'underline',
    },
    '.languageNavigation': {
      [Breakpoints.TabletUp.mq]: {
        padding: '8px',
      },
    },
  },
  // this cheats out bootstraps navbar, so it works for the lang bar on mobile
  '& .linkNav-mobile-container': {
    flex: 1,
    '& .langNav': {
      flexDirection: 'row',
      '& .nav-item': {
        paddingRight: '1em',
      },
    },
  },
  // mobile menu
  '& .mobile-menu-button-container': {
    zIndex: 1200,
    position: 'absolute',
    right: '0',
    top: '0',
  },
  '& .bm-item-list': {
    color: colorEnum.black,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '& .bm-menu': {
    background: colorEnum.white,
    fontSize: '1.15em',
  },
  '& .mobile-menu-navigation': {
    paddingLeft: '30px',
    '& li': {
      ...font.GNBook,
      paddingLeft: '4px',
      marginBottom: '15px',
      color: colorEnum.lightBlue,
      listStyleType: 'none',
      '& a': {
        fontSize: '30px',
        letterSpacing: '-0.19px',
        color: colorEnum.black,
        verticalAlign: 'sub',
      },
      '&.active': {
        listStyleType: 'disc',
      },
    },
  },
  // general color control
  '&.white': {
    '.navbar-light .navbar-nav .nav-link, .nav-item a span, .active span': {
      color: colorEnum.white,
    },
    '.foldbutton .flipBar': {
      backgroundColor: colorEnum.white,
    },
    '.navbar-light .linkNav .nav-list-indicator-dot': {
      color: colorEnum.lightBlue,
    },
  },
  '&.black': {
    '.navbar-light .navbar-nav .nav-link, .nav-item a span, .active span': {
      color: colorEnum.black,
    },
    '.foldbutton .flipBar': {
      backgroundColor: colorEnum.black,
    },
    '.navbar-light .linkNav .nav-list-indicator-dot': {
      color: colorEnum.blue,
    },
  },
});

const isSamePage = (page1, page2) =>
  page1 && page2 && page1.contentfulEntryId === page2.contentfulEntryId;

type HeaderProps = {
  pages?: InternalPropTypes.Page[];
  currentPage?: InternalPropTypes.Page;
  language: InternalPropTypes.Language;
  className?: string;
  currentPageAlternates?: InternalPropTypes.Page;
};

type HeaderInnerProps = WithAppConfigProps & HeaderProps;

interface IHeaderState {
  mobileMenuOpen: boolean;
}

class Header extends Component<HeaderInnerProps, IHeaderState> {
  static defaultProps = {
    pages: [],
    currentPage: null,
    className: null,
    currentPageAlternates: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.languageLinks = this.languageLinks.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ mobileMenuOpen: false });
    }
  }

  toggleMenu() {
    this.setState((state, _) => ({
      mobileMenuOpen: !state.mobileMenuOpen,
    }));
  }

  languageLinks() {
    const { currentPage, currentPageAlternates, language } = this.props;
    return Language.LANGUAGES.map((lang) => {
      const pageRef = currentPageAlternates?.alternatePages?.find(
        (page) => page.language === lang.name,
      );
      const to = pageRef?.pageSlug ?? currentPage.slug.page;
      return (
        <Nav.Item key={lang.iso} as="li">
          <NavLink
            className={`languageNavigation ${
              lang.iso === language.iso && 'currentLanguage'
            }`}
            key={lang.iso}
            to={to}
          >
            <Caption type={Caption.types.HERO} as="span">
              {lang.urlLanguage.replace(/^\w/, (chr) => chr.toUpperCase())}
            </Caption>
          </NavLink>
        </Nav.Item>
      );
    });
  }

  render() {
    const { pages, currentPage, className } = this.props;
    const { mobileMenuOpen } = this.state;
    // override color if mobile menu is shown
    let currentColor: string = MenuColorEnum.BLACK;
    let logoImgSrc = logoBlack;
    if (!mobileMenuOpen && currentPage?.menuColor) {
      currentColor = currentPage.menuColor.toLowerCase();
      logoImgSrc =
        currentPage.menuColor === MenuColorEnum.WHITE ? logoWhite : logoBlack;
    }
    const languageItems = this.languageLinks();

    return (
      <header
        css={useStyles}
        className={currentColor + (className ? ` ${className}` : '')}
      >
        {/* Mobile */}
        <Menu
          isOpen={this.state.mobileMenuOpen}
          right
          customBurgerIcon={false}
          customCrossIcon={false}
          noOverlay
          disableOverlayClick
          disableAutoFocus
          pageWrapId="page_wrap"
          outerContainerId="outer_container"
          width="100%"
          itemListElement="div"
        >
          <nav
            className="mobile-menu-navigation-menu"
            aria-label="Mobile navigation"
          >
            <ul className="mobile-menu-navigation">
              {pages.map((page) => {
                const pageUrl = page.linkTo;
                const isCurrentLocation = isSamePage(currentPage, page);
                return (
                  <li
                    key={page.contentfulEntryId}
                    className={isCurrentLocation ? 'active' : ''}
                  >
                    <Link to={pageUrl}>{page.seo.title}</Link>
                  </li>
                );
              }, this)}
            </ul>
          </nav>
        </Menu>
        <MediaQuery
          visible={Breakpoints.Mobile}
          className="mobile-menu-button-container"
        >
          <FoldButton
            onChange={this.toggleMenu}
            isOpen={this.state.mobileMenuOpen}
          />
        </MediaQuery>

        {/* Desktop */}
        <ContentOuterBound>
          <Navbar expand="md" className="navBar" aria-label="Main navigation">
            <Navbar.Brand>
              <NavLink to="/">
                <img src={logoImgSrc} alt="logo" />
              </NavLink>
            </Navbar.Brand>

            <MediaQuery
              visible={Breakpoints.Mobile}
              className="linkNav-mobile-container"
            >
              <Nav className="langNav" activeKey="/" as="ul">
                {languageItems}
              </Nav>
            </MediaQuery>

            <Navbar.Collapse className="collapse">
              <Nav className="mr-auto langNav" activeKey="/" as="ul">
                {languageItems}
              </Nav>
              <Nav className="linkNav" as="ul">
                <NavListIndicator>
                  {pages.map((page) => {
                    const pageUrl = page.linkTo;
                    const isCurrentLocation = isSamePage(currentPage, page);
                    return (
                      <Nav.Item
                        as="li"
                        key={page.name}
                        className={`linkNavItem ${
                          isCurrentLocation ? ' active' : ''
                        }`}
                      >
                        {isCurrentLocation ? (
                          <Caption type={Caption.types.SOCIAL} as="span">
                            {page.seo.title}
                          </Caption>
                        ) : (
                          <NavLink to={pageUrl} className="linkNavItemLink">
                            <Caption type={Caption.types.SOCIAL} as="span">
                              {page.seo.title}
                            </Caption>
                          </NavLink>
                        )}
                      </Nav.Item>
                    );
                  })}
                </NavListIndicator>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </ContentOuterBound>
      </header>
    );
  }
}

export default compose<HeaderInnerProps, HeaderProps>(
  withAppConfig,
  withRouter,
)(Header);
