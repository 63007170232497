import cmsContentTypes from './cms/content-types';
import cmsBlockTypes from './cms/block-types';
import cmsBlockContentTypes from './cms/block-content-types';
import cmsMenuColors from './cms/menu-colors';
import cmsPagePresentations from './cms/page-presentations';
import cmsControlOptions from './cms/control-options';
import cmsCallToActionTypes from './cms/control-options/call-to-action-types';
import cmsCallToActionWithAlignmentTypes from './cms/control-options/call-to-action-with-alignment-types';
import cmsHeaderTextGroupTypes from './cms/control-options/header-text-group-types';
import cmsHeroTypes from './cms/control-options/hero-types';
import cmsList3GroupsTypes from './cms/control-options/list-3-groups-types';
import cmsList3GroupsHeaders from './cms/control-options/list-3-groups-headers';
import cmsPageTags from './cms/control-options/page-tags';
import cmsPositions from './cms/control-options/positions';
import cmsQuoteTypes from './cms/control-options/quote-types';
import cmsWellTypes from './cms/control-options/well-types';
import cmsKeyFigureGridTypes from './cms/control-options/key-figure-grid-types';
import cmsProfileGridTypes from './cms/control-options/profile-grid-types';
import cmsProfileTypes from './cms/control-options/profile-types';
import cmsFactBoxTypes from './cms/control-options/fact-box-types';
import cmsSEOWeights from './cms/control-options/seo-weights';
import cmsBodyTextWidth from './cms/control-options/BodyTextWidth';
import backgroundColors from './background-color';
import fontColors from './fontColors';
import cmsIsProductPageValues from './cms/control-options/isProductPage-values';

// content type
export const ContentTypeEnum = cmsContentTypes;
export const ContentTypes = Object.values(ContentTypeEnum);
export type TypeContentTypes = typeof ContentTypes[number];

// block type
export const BlockTypeEnum = cmsBlockTypes;
export const BlockTypes = Object.values(BlockTypeEnum);
export type TypeBlockTypes = typeof BlockTypes[number];

// block content type
export const BlockContentTypeEnum = cmsBlockContentTypes;
export const BlockContentTypes = Object.values(BlockContentTypeEnum);
export type TypeBlockContentTypes = typeof BlockContentTypes[number];

// body text width
export const BodyTextWidthEnum = cmsBodyTextWidth;
export const BodyTextWidthTypes = Object.values(BodyTextWidthEnum);
export type TypeBodyTextWidthTypes = typeof BodyTextWidthTypes[number];

// menu color
export const MenuColorEnum = cmsMenuColors;
export const MenuColors = Object.values(MenuColorEnum);
export type TypeMenuColorTypes = typeof MenuColors[number];

// page presentation
export const PagePresentationEnum = cmsPagePresentations;
export const PagePresentations = Object.values(PagePresentationEnum);
export type TypePagePresentationTypes = typeof PagePresentations[number];

// fixed control options
export const FixedControlOptionEnum = cmsControlOptions;
export const FixedControlOptions = Object.values(FixedControlOptionEnum);
export type TypeFixedControlOptionTypes = typeof FixedControlOptions[number];

// font colors
export const fontColorEnum = fontColors;
export const FontColors = Object.values(fontColorEnum);
export type TypeFontColorTypes = typeof FontColors[number];

// background color
export const BackgroundColorEnum = backgroundColors;
export const BackgroundColors = Object.values(BackgroundColorEnum);
export type TypeBackgroundColorTypes = typeof BackgroundColors[number];

// call to action type
export const CallToActionTypeEnum = cmsCallToActionTypes;
export const CallToActionTypes = Object.values(CallToActionTypeEnum);
export type TypeCallToActionTypes = typeof CallToActionTypes[number];

// call to action with header type
export const CallToActionWithAlignmentTypeEnum =
  cmsCallToActionWithAlignmentTypes;
export const CallToActionWithAlignmentTypes = Object.values(
  CallToActionWithAlignmentTypeEnum,
);
export type TypeCallToActionWithAlignmentTypes =
  typeof CallToActionWithAlignmentTypes[number];

// header text group type
export const HeaderTextGroupTypeEnum = cmsHeaderTextGroupTypes;
export const HeaderTextGroupTypes = Object.values(HeaderTextGroupTypeEnum);
export type TypeHeaderTextGroupTypes = typeof HeaderTextGroupTypes[number];

// hero type
export const HeroTypeEnum = cmsHeroTypes;
export const HeroTypes = Object.values(HeroTypeEnum);
export type TypeHeroTypes = typeof HeroTypes[number];

// list 3 group type
export const List3GroupsTypeEnum = cmsList3GroupsTypes;
export const List3GroupsTypes = Object.values(List3GroupsTypeEnum);
export type TypeList3GroupsTypes = typeof List3GroupsTypes[number];

// list 3 group header
export const List3GroupsHeadersEnum = cmsList3GroupsHeaders;
export const List3GroupsHeaders = Object.values(List3GroupsHeadersEnum);
export type TypeList3GroupsHeaders = typeof List3GroupsHeaders[number];

// page tag
export const PageTagEnum = cmsPageTags;
export const PageTags = Object.values(PageTagEnum);
export type TypePageTags = typeof PageTags[number];

// position
export const PositionEnum = cmsPositions;
export const Positions = Object.values(PositionEnum);
export type TypePositionTypes = typeof Positions[number];

// quote type
export const QuoteTypeEnum = cmsQuoteTypes;
export const QuoteTypes = Object.values(QuoteTypeEnum);
export type TypeQuoteTypes = typeof QuoteTypes[number];

// well type
export const WellTypeEnum = cmsWellTypes;
export const WellTypes = Object.values(WellTypeEnum);
export type TypeWellTypes = typeof WellTypes[number];

// key figure grid type
export const KeyFigureGridTypeEnum = cmsKeyFigureGridTypes;
export const KeyFigureGridTypes = Object.values(KeyFigureGridTypeEnum);
export type TypeKeyFigureGridTypes = typeof KeyFigureGridTypes[number];

// profile grid type
export const ProfileGridEnum = cmsProfileGridTypes;
export const ProfileGridTypes = Object.values(ProfileGridEnum);
export type TypeProfileGridTypes = typeof ProfileGridTypes[number];

// profile type
export const ProfileEnum = cmsProfileTypes;
export const ProfileTypes = Object.values(ProfileEnum);
export type TypeProfileTypes = typeof ProfileTypes[number];

// fact box type
export const FactBoxEnum = cmsFactBoxTypes;
export const FactboxTypes = Object.values(FactBoxEnum);
export type TypeFactBoxTypes = typeof ProfileTypes[number];

// SEO weight type
export const SEOWeightsEnum = cmsSEOWeights;
export const SEOWeights = Object.values(SEOWeightsEnum);
export type TypeSEOWeightTypes = typeof SEOWeights[number];

export const IsProductPageEnum = cmsIsProductPageValues;
export const IsProductPageValues = Object.values(IsProductPageEnum);
export type TypeIsProductPageValues = typeof IsProductPageValues[number];
