import * as Breakpoints from '../constants/breakpoints';

import { SizesType } from '../types/ts/imgixQuery';

const mobileConfig = {
  screenSize: Breakpoints.Mobile,
  paddingTop: [0, 0, 0, 0, 0, 0],
  paddingLeft: [0, 0, 0, 0, 0, 0],
  collapsedView: 3,
};

const tabletConfig = {
  screenSize: Breakpoints.Tablet,
  paddingTop: [60, 0, 20, 60, 50, 0],
  paddingLeft: [0, 20, 0, 20, 0, 20],
  collapsedView: 6,
};

const desktopConfig = {
  screenSize: Breakpoints.DesktopUp,
  paddingTop: [70, 0, 30, 0, 80, 20],
  paddingLeft: [0, 30, 30, 0, 30, 30],
  collapsedView: 6,
};

const gridLayout = [desktopConfig, tabletConfig, mobileConfig];

export const generateArticleGridScreen = (screen) => {
  const paddingTop = screen.paddingTop.reduce(
    (t, c, i) =>
      `${t} div:nth-of-type(6n+${
        i + 1
      }) * div.image-container { padding-top: ${c}px;}`,
    '',
  );
  const paddingLeft = screen.paddingLeft.reduce(
    (t, c, i) =>
      `${t} div:nth-of-type(6n+${i + 1}) a > * { padding-${
        c > 0 ? 'left' : 'right'
      }: ${Math.abs(c)}px;}`,
    '',
  );
  const showFew = `.article-container + .view-controller:nth-of-type(n+${
    screen.collapsedView + 1
  }) {display: none}`;
  return `${paddingTop} ${paddingLeft} ${showFew}`;
};

export const generateArticleGrid = () =>
  gridLayout.reduce(
    (total, screen) =>
      `${total} ${screen.screenSize.mq} {${generateArticleGridScreen(screen)}}`,
    '',
  );

export const getArticleGridImageSizes = (index): SizesType => {
  const result = {
    sm: {
      w: (
        504 -
        Math.abs(
          mobileConfig.paddingLeft[index % mobileConfig.paddingLeft.length],
        )
      ).toString(),
      h: (
        336 - mobileConfig.paddingTop[index % mobileConfig.paddingTop.length]
      ).toString(),
    },
    md: {
      w: (
        436 -
        Math.abs(
          tabletConfig.paddingLeft[index % tabletConfig.paddingLeft.length],
        )
      ).toString(),
      h: (
        256 - tabletConfig.paddingTop[index % tabletConfig.paddingTop.length]
      ).toString(),
    },
    lg: {
      w: (
        318 -
        Math.abs(
          desktopConfig.paddingLeft[index % desktopConfig.paddingLeft.length],
        )
      ).toString(),
      h: (
        246 - desktopConfig.paddingTop[index % desktopConfig.paddingTop.length]
      ).toString(),
    },
  };

  return result;
};
