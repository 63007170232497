import React, { Component, useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import useEmblaCarousel from 'embla-carousel-react';

import Image from '../atoms/Image';
import ArrowButton from '../atoms/ArrowButton';
import Hstack3CellExtremity from '../atoms/Hstack3CellExtremity';
import TitleTextGroup from './TitleTextGroup';

import { colorEnum } from '../../../constants/colors';
import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';
import NwLink from '../NwLink';
import Title from '../atoms/Title';
import TitleTypes from '../../../constants/title-types';
import { TypeBackgroundColorTypes } from '../../../constants/cms-constants';

const styles = css({
  '& .caption': {
    opacity: '0.4',
  },
  '& .hstack-3-cell-extremety': {
    [Breakpoints.Mobile.mq]: {
      marginBottom: '16px',
    },
    [Breakpoints.TabletUp.mq]: {
      marginBottom: '32px',
    },
  },
  '& .image-container': {
    width: '25%',
  },
  '& .image': {
    objectFit: 'scale-down',
    overflow: 'hidden',
    height: 'auto',
    width: '100%',
    [Breakpoints.Mobile.mq]: {
      paddingLeft: '15px',
      paddingRight: '15px',
      height: '125px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      height: '175px',
    },
    [Breakpoints.DesktopUp.mq]: {
      paddingLeft: '25px',
      paddingRight: '25px',
      height: '225px',
    },
  },
  '& .buttons': {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
    '& button': {
      marginLeft: '8px',
    },
  },
  '.embla__viewport': {
    overflow: 'hidden',
    [Breakpoints.Mobile.mq]: {
      marginBottom: '56px',
    },
    [Breakpoints.Tablet.mq]: {
      marginBottom: '64px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginBottom: '72px',
    },
  },
  '.embla__container': {
    backfaceVisibility: 'hidden',
    display: 'flex',
    alignItems: 'top',
    touchAction: 'pan-y',
    marginLeft: '-25px',
    marginRight: '-25px',
  },
  '.embla__slide': {
    display: 'flex',
    justifyContent: 'center',
    flex: '0 0 100%',
    minWidth: 0,
    '& .picture': {
      display: 'contents',
    },
  },
  '& .text_field': {
    marginLeft: '25px',
    marginRight: '25px',
    verticalAlign: 'bottom',
  },
  '& .scroll': {
    width: '100%',
    display: 'flex',
    height: '2px',
    paddingBottom: '10px',
    '& .active': {
      transition: 'margin 400ms',
      height: '2px',
      backgroundColor: colorEnum.black,
    },
  },
});

type ImageCarouselProps = {
  headerText: InternalPropTypes.RichText;
  mediaItems?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
};

type EmblaCarouselProps = {
  headerText: InternalPropTypes.RichText;
  mediaItems: InternalPropTypes.MediaItem[];
  sizes: SizesType;
  scrollWidth: number;
  itemsPerSlide: number;
};

function GroupImages(mediaItems, itemsPerSlide) {
  return (
    (mediaItems.length >= itemsPerSlide &&
      [mediaItems.splice(0, itemsPerSlide)].concat(
        GroupImages(mediaItems, itemsPerSlide),
      )) ||
    []
  );
}

function EmblaCarousel(props: EmblaCarouselProps) {
  // eslint-disable-next-line react/prop-types
  const { headerText, mediaItems, sizes, scrollWidth, itemsPerSlide } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [scrollProgress, setScrollProgress] = useState(0);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onScroll = useCallback(
    (emblaApi) => {
      const progress = scrollWidth * emblaApi.selectedScrollSnap();
      setScrollProgress(progress);
    },
    [scrollWidth],
  );

  useEffect(() => {
    if (!emblaApi) return;

    onScroll(emblaApi);
    emblaApi.on('reInit', onScroll);
    emblaApi.on('scroll', onScroll);
  }, [emblaApi, onScroll]);

  const showNavigation = mediaItems.length > itemsPerSlide;

  return (
    <>
      <Hstack3CellExtremity
        onRenderLeftCell={() => (
          <Title type={TitleTypes.SECTION}>{headerText}</Title>
        )}
        onRenderRightCell={() => (
          <div className="buttons">
            {showNavigation && (
              <ArrowButton onClick={onPrevButtonClick} direction="left" />
            )}
            {showNavigation && <ArrowButton onClick={onNextButtonClick} />}
          </div>
        )}
      />

      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {
              // eslint-disable-next-line react/prop-types
              GroupImages([...mediaItems], itemsPerSlide).map(
                (mediaItemGroup, i) => (
                  /* eslint-disable-next-line react/no-array-index-key, prefer-template */
                  <div className="embla__slide" key={'slide:' + i}>
                    {mediaItemGroup.map((media, j) => (
                      <div
                        className="image-container"
                        /* eslint-disable-next-line react/no-array-index-key, prefer-template */
                        key={media.contentfulEntryId + j}
                      >
                        <NwLink
                          key={media.link?.name}
                          to={media.link?.linkTo}
                          openInNewWindowOrTab={
                            media.link?.openInNewWindowOrTab
                          }
                        >
                          <Image image={media?.image} sizes={sizes} />
                          <div className="text_field">
                            <TitleTextGroup bodyText={media.header} />
                          </div>
                        </NwLink>
                      </div>
                    ))}
                  </div>
                ),
              )
            }
          </div>
        </div>
        <div className="scroll">
          {showNavigation && (
            <div
              className="active"
              style={{
                width: `${scrollWidth}%`,
                marginLeft: `${scrollProgress}%`,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

class MediaCarousel extends Component<ImageCarouselProps> {
  static defaultProps = {
    mediaItems: [],
    backgroundColor: null,
  };

  render() {
    const { headerText, mediaItems, backgroundColor } = this.props;
    const itemsPerSlide = 4;
    const selectedLength =
      mediaItems.length - (mediaItems.length % itemsPerSlide);
    const selectedMediaItems = mediaItems.slice(0, selectedLength);
    const scrollWidth = 100 / (selectedLength / itemsPerSlide);
    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '125', h: '125', ar: '1:1' },
      [ScreenSize.Tablet]: { w: '175', h: '175', ar: '1:1' },
      [ScreenSize.Desktop]: { w: '225', h: '225', ar: '1:1' },
    };

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div className="media-carousel-molecule" css={[styles, addStyle]}>
        <EmblaCarousel
          headerText={headerText}
          mediaItems={selectedMediaItems}
          sizes={sizes}
          scrollWidth={scrollWidth}
          itemsPerSlide={itemsPerSlide}
        />
      </div>
    );
  }
}

export default MediaCarousel;
