/* eslint-disable react/prop-types */
import { Component } from 'react';
import { css } from '@emotion/react';
import { compose } from 'recompose';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import CookieSettingsMolecule from './common/molecules/CookieSettings';
import BodyText from './common/atoms/BodyText';
import withAppConfig, { WithAppConfigProps } from '../utils/hocs/withAppConfig';
import HstackSpacing from './common/atoms/HstackSpacing';
import RichTextWrapper from './common/RichTextWrapper';

import Consent from '../utils/Consent';
import RichTextRenderer from '../utils/content/RichTextRenderer';

import * as InternalPropTypes from '../constants/internal-types';
import backgroundColors from '../constants/background-color';
import fontColors from '../constants/fontColors';
import * as Breakpoints from '../constants/breakpoints';
import { CONSENTCATEGORIES, consentEnum } from '../constants/consentCategories';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = css({
  backgroundColor: backgroundColors.TRANSPARENT,
  paddingTop: '15px',
  paddingBottom: '10px',
  color: fontColors.WHITE,
  '& .submit-button': {
    padding: '6px 12px',
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    [Breakpoints.DesktopUp.mq]: {
      margin: '5px 50px 5px 50px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '20px',
    },
    [Breakpoints.Tablet.mq]: {
      fontSize: '20px',
      margin: '5px auto 5px 50px',
    },
    [Breakpoints.MobileDown.mq]: {
      fontSize: '15px',
      margin: '5px auto 5px 0px',
      padding: '4px 8px',
    },
    '&.accept-button': {
      backgroundColor: backgroundColors.BLUE,
      p: {
        color: fontColors.WHITE,
      },
    },
    '&.save-button': {
      backgroundColor: backgroundColors.WHITE,
    },
  },
});

type CookieSettingsProps = {
  consentMessage?: InternalPropTypes.RichText;
  cookiePolicyVersion?: string;
  acceptAllButtonText?: string;
  acceptNecessaryButtonText?: string;
  saveSettingsButtonText?: string;
  backgroundColor?: TypeBackgroundColorTypes;
  language?: string;
  propConsent?: Consent;
};

type CookieSettingsInnerProps = WithAppConfigProps & CookieSettingsProps;
interface ICookieSettingsState {
  consent: Consent;
}

class CookieSettings extends Component<
  CookieSettingsInnerProps,
  ICookieSettingsState
> {
  static defaultProps = {
    consentMessage: null,
    cookiePolicyVersion: null,
    acceptAllButtonText: null,
    acceptNecessaryButtonText: null,
    saveSettingsButtonText: null,
    backgroundColor: null,
    language: null,
    propConsent: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      consent:
        this.props.propConsent ?? new Consent('all', props.cookiePolicyVersion),
    };

    this.setHandleChange = this.setHandleChange.bind(this);
    this.handleSubmitAcceptAll = this.handleSubmitAcceptAll.bind(this);
    this.handleSubmitAcceptNecessary =
      this.handleSubmitAcceptNecessary.bind(this);
    this.handleSubmitSave = this.handleSubmitSave.bind(this);
  }

  handleSubmitAcceptAll() {
    const consent = new Consent('all', this.props.cookiePolicyVersion);
    this.props.appConfig.eventHandlers.onCookieConsentGiven(consent);
  }

  handleSubmitAcceptNecessary() {
    const consent = new Consent('necessary', this.props.cookiePolicyVersion);
    this.props.appConfig.eventHandlers.onCookieConsentGiven(consent);
  }

  handleSubmitSave() {
    this.props.appConfig.eventHandlers.onCookieConsentGiven(this.state.consent);
  }

  setHandleChange(key) {
    return (e) => {
      this.setState((prevState) => ({
        consent: prevState.consent.updateCookieConsent(key, e),
      }));
    };
  }

  render() {
    const {
      consentMessage,
      acceptAllButtonText,
      acceptNecessaryButtonText,
      saveSettingsButtonText,
      backgroundColor,
      language,
    } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <RichTextWrapper
              richText={consentMessage}
              renderFunction={RichTextRenderer.textHeadersLinksAndListsInDiv}
            />
            {CONSENTCATEGORIES.map((item) => (
              <div key={item.key}>
                <CookieSettingsMolecule
                  disabled={item.key === consentEnum.NECESSARY}
                  name={item.key}
                  cookieTypeText={item.text[language]}
                  value={this.state.consent[item.key]}
                  onChange={this.setHandleChange(item.key)}
                />
              </div>
            ))}
            <HstackSpacing spacing={HstackSpacing.CENTER}>
              {[
                [acceptAllButtonText, 'accept', this.handleSubmitAcceptAll],
                [
                  acceptNecessaryButtonText,
                  'accept',
                  this.handleSubmitAcceptNecessary,
                ],
                [saveSettingsButtonText, 'save', this.handleSubmitSave],
              ].map(([text, buttonType, handleSubmit]) => (
                <button
                  key={text}
                  type="submit"
                  className={`submit-button ${buttonType}-button`}
                  onClick={handleSubmit}
                >
                  {text && <BodyText>{text}</BodyText>}
                </button>
              ))}
            </HstackSpacing>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default compose<CookieSettingsInnerProps, CookieSettingsProps>(
  withAppConfig,
)(CookieSettings);
