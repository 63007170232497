import { Component } from 'react';
import { compose } from 'recompose';
import ContentSpacer from './common/atoms/ContentSpacer';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import * as InternalPropTypes from '../constants/internal-types';
import SideboxMolecule from './common/molecules/Sidebox';
import withImageHelperFactory, {
  WithImageHelperFactoryProps,
} from '../utils/hocs/withImageHelperFactory';
import {
  TypeBackgroundColorTypes,
  TypeFontColorTypes,
} from '../constants/cms-constants';

type SideboxProps = {
  headerText?: InternalPropTypes.RichText;
  text?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
  video?: InternalPropTypes.Video;
  factBoxTitleText?: InternalPropTypes.RichText;
  factBoxBodyText?: InternalPropTypes.RichText;
  factBoxFontColor?: TypeFontColorTypes;
  factBoxBackgroundColor?: TypeBackgroundColorTypes;
};

type SideboxInnerProps = WithImageHelperFactoryProps & SideboxProps;

export class Sidebox extends Component<SideboxInnerProps> {
  static defaultProps = {
    headerText: null,
    text: null,
    image: null,
    video: null,
    factBoxTitleText: null,
    factBoxBodyText: null,
    factBoxFontColor: null,
    factBoxBackgroundColor: null,
  };

  render() {
    const {
      headerText,
      text,
      image: asset,
      video,
      factBoxTitleText,
      factBoxBodyText,
      imageHelperFactory,
      factBoxFontColor,
      factBoxBackgroundColor,
    } = this.props;

    const imageHelper = imageHelperFactory.createImageHelper(asset);
    const isAnimation = imageHelper?.isAnimation();

    let image;
    let animation;

    if (isAnimation) {
      animation = imageHelper.getRawImage() as InternalPropTypes.ImageFile;
    } else if (asset) {
      image = asset;
    }

    return (
      <ContentOuterBound>
        <ContentSpacer>
          <SideboxMolecule
            headerText={headerText}
            text={text}
            image={image}
            video={video}
            animation={animation}
            factBoxTitleText={factBoxTitleText}
            factBoxBodyText={factBoxBodyText}
            factBoxFontColor={factBoxFontColor}
            factBoxBackgroundColor={factBoxBackgroundColor}
          />
        </ContentSpacer>
      </ContentOuterBound>
    );
  }
}

export default compose<SideboxInnerProps, SideboxProps>(withImageHelperFactory)(
  Sidebox,
);
