import { Component } from 'react';
import { css } from '@emotion/react';
import * as InternalPropTypes from '../../../constants/internal-types';
import FactBox from './FactBox';
import BodyText from '../atoms/BodyText';
import SVGRenderer from '../atoms/SVGRenderer';
import Image from '../atoms/Image';
import { SizesType } from '../../../types/ts/imgixQuery';
import ScreenSize from '../../../constants/screenSize';
import Header from '../atoms/Header';
import Vstack3CellExtremity from '../atoms/Vstack3CellExtremity';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import {
  TypeBackgroundColorTypes,
  TypeFontColorTypes,
} from '../../../constants/cms-constants';
import * as Breakpoints from '../../../constants/breakpoints';
import Video from '../atoms/Video';

const headerStyle = {
  '& .header-text': {
    paddingTop: '32px',
    paddingBottom: '32px',
  },
};

const leftColumnStyle = css({
  [Breakpoints.TabletUp.mq]: {
    paddingRight: '20px',
  },
  height: '100%',
  width: '100%',
  '& video': {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
});

const rightColumnStyle = {
  [Breakpoints.TabletUp.mq]: {
    paddingLeft: '20px',
  },
};

type SideboxProps = {
  headerText?: InternalPropTypes.RichText;
  text?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
  animation?: InternalPropTypes.ImageFile;
  video?: InternalPropTypes.Video;
  factBoxTitleText?: InternalPropTypes.RichText;
  factBoxBodyText?: InternalPropTypes.RichText;
  factBoxFontColor?: TypeFontColorTypes;
  factBoxBackgroundColor?: TypeBackgroundColorTypes;
};

const sizes: SizesType = {
  [ScreenSize.Mobile]: { w: '743', h: '370' },
  [ScreenSize.Tablet]: { w: '260', h: '338' },
  [ScreenSize.Desktop]: { w: '300', h: '380' },
};

class Sidebox extends Component<SideboxProps> {
  static defaultProps = {
    headerText: null,
    text: null,
    image: null,
    video: null,
    animation: null,
    factBoxTitleText: null,
    factBoxBodyText: null,
    factBoxFontColor: null,
    factBoxBackgroundColor: null,
  };

  render() {
    const {
      headerText,
      text,
      image,
      animation,
      video,
      factBoxTitleText,
      factBoxBodyText,
      factBoxFontColor,
      factBoxBackgroundColor,
    } = this.props;

    const factBoxStyle = {
      '& .title': {
        color: factBoxFontColor,
      },
      '& .body-text': {
        color: factBoxFontColor,
      },
      '& .title-text-group': {
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingLeft: '22px',
        paddingRight: '22px',
      },
      background: factBoxBackgroundColor,
    };

    const CompLoader = () => {
      if (animation) {
        return (
          <div css={leftColumnStyle}>
            <SVGRenderer animation={animation} />
          </div>
        );
      }

      if (image) {
        return (
          <div css={leftColumnStyle}>
            <Image className="image" image={image} sizes={sizes} />
          </div>
        );
      }

      if (video) {
        return (
          <div className="video" css={leftColumnStyle}>
            <Video sources={video.videos} />
          </div>
        );
      }

      if (text) {
        return (
          <div css={leftColumnStyle}>
            <BodyText className="text">{text}</BodyText>
          </div>
        );
      }

      return null;
    };

    return (
      <div css={headerStyle}>
        <Vstack3CellExtremity
          onRenderTopCell={() => <Header type="article">{headerText}</Header>}
          onRenderMiddleCell={() => (
            <Grid2CellUnevenSplit
              leftCellCount={7}
              rightCellCount={5}
              hasGroove
              onRenderCell1={CompLoader}
              onRenderCell2={() => (
                <div css={rightColumnStyle}>
                  <FactBox
                    titleText={factBoxTitleText}
                    bodyText={factBoxBodyText}
                    styling={factBoxStyle}
                  />
                </div>
              )}
            />
          )}
        />
      </div>
    );
  }
}

export default Sidebox;
