import { Component } from 'react';
import { compose } from 'recompose';
import MediaCarousel from './common/molecules/MediaCarousel';
import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';
import withAppConfig, { WithAppConfigProps } from '../utils/hocs/withAppConfig';
import { AppConfigType } from '../client-server-utils/appConfig';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import PageTags from '../constants/cms/control-options/page-tags';

const styles = {
  '&.related-pages-grid-component': {
    [Breakpoints.DesktopUp.mq]: {
      paddingTop: '72px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingTop: '80px',
    },
    [Breakpoints.Mobile.mq]: {
      paddingTop: '64px',
    },
  },
};

function getTagsFromPage(page) {
  const { primaryPageTag, secondaryPageTag } = page.controlOptionsV2;

  return [primaryPageTag, secondaryPageTag];
}

function getIsProductPage(page) {
  const { isProductPage } = page.controlOptionsV2;

  return isProductPage;
}

function createMediaItemFromPage(page) {
  const linkImage: InternalPropTypes.Image = {
    ...page.featuredImage,
    alternateText: page.seo.title,
  };

  return {
    image: linkImage,
    header: page.featuredTitle,
    link: { linkTo: page.linkTo },
    createdAt: page.createdAt,
    contentfulEntryId: page.featuredImage.contentfulEntryId,
  };
}

function sortByDate(array) {
  array.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
  return array;
}

function isValidRelatedPage(page, currentContentfulId) {
  return (
    page.controlOptionsV2 !== undefined &&
    page.featuredTitle !== undefined &&
    page.featuredImage !== null &&
    page.contentfulEntryId !== currentContentfulId
  );
}

function getRelatedPages(appConfig: AppConfigType, maxItems) {
  if (!appConfig.relatedPagesInfo) {
    return [];
  }

  const { allPages, currentPageId } = appConfig.relatedPagesInfo;

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const currentTags = getTagsFromPage(
    allPages.filter((elm) => elm.contentfulEntryId === currentPageId)[0],
  ).filter((tag) => tag !== getKeyByValue(PageTags, PageTags.NONE));

  function safeCompareTag(currentTag, otherTag) {
    if (
      (currentTag === undefined && otherTag === undefined) ||
      (currentTag === PageTags.NONE && otherTag === PageTags.NONE)
    ) {
      return false;
    }

    return currentTag === otherTag;
  }

  function getMatchesIndex(currentTags, otherTags, isProductPage) {
    const currentPrimary = PageTags[currentTags[0]];
    const currentSecondary = PageTags[currentTags[1]];
    const otherPrimary = PageTags[otherTags[0]];
    const otherSecondary = PageTags[otherTags[1]];

    if (safeCompareTag(currentPrimary, otherPrimary) && isProductPage) {
      return 0;
    }
    if (safeCompareTag(currentSecondary, otherPrimary) && isProductPage) {
      return 1;
    }
    if (
      safeCompareTag(currentPrimary, otherPrimary) &&
      safeCompareTag(currentSecondary, otherSecondary)
    ) {
      return 2;
    }
    if (
      safeCompareTag(currentPrimary, otherSecondary) &&
      safeCompareTag(currentSecondary, otherPrimary)
    ) {
      return 3;
    }
    if (safeCompareTag(currentPrimary, otherPrimary)) {
      return 4;
    }
    if (safeCompareTag(currentPrimary, otherSecondary)) {
      return 5;
    }
    if (safeCompareTag(currentSecondary, otherPrimary)) {
      return 6;
    }
    if (safeCompareTag(currentSecondary, otherSecondary)) {
      return 7;
    }
    return null;
  }

  const matches = [[], [], [], [], [], [], [], []];
  allPages.forEach((page) => {
    if (isValidRelatedPage(page, currentPageId)) {
      const otherTags = getTagsFromPage(page);
      const isProductPage = getIsProductPage(page);
      const idx = getMatchesIndex(currentTags, otherTags, isProductPage);
      if (idx != null) {
        matches[idx].push(createMediaItemFromPage(page));
      }
    }
  });

  return matches
    .map((list) => sortByDate(list))
    .flat()
    .slice(0, maxItems);
}

type RelatedPagesGridProps = {
  headerText?: InternalPropTypes.RichText;
  mediaItems?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
};

type RelatedPagesGridInnerProps = WithAppConfigProps & RelatedPagesGridProps;

class RelatedPagesGridComponent extends Component<RelatedPagesGridInnerProps> {
  static defaultProps = {
    headerText: null,
    mediaItems: null,
    backgroundColor: null,
  };

  render() {
    const { mediaItems, headerText, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    const minItems = 4;
    const maxItems = 8;
    const relatedMediaItems = getRelatedPages(
      this.props.appConfig,
      maxItems,
    ).concat(mediaItems);
    return relatedMediaItems.length >= minItems ? (
      <div className="related-pages-grid-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <MediaCarousel
              headerText={headerText}
              mediaItems={relatedMediaItems}
              backgroundColor={backgroundColor}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    ) : null;
  }
}

export default compose<RelatedPagesGridInnerProps, RelatedPagesGridProps>(
  withAppConfig,
)(RelatedPagesGridComponent);
