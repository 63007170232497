const PageTags = {
  API_INTEGRATION_PLATFORMS: 'api-integration-platforms',
  CLOUD_TRANSFORMATION: 'cloud-transformation',
  CROSS_PLATFORM_APPS: 'cross-platform-apps',
  DATA_AI: 'data-ai',
  DEVOPS_WAY_OF_WORK: 'devops-way-of-work',
  DIGITAL_PRODUCT_DEVELOPMENT: 'digital-product-development',
  ENTERPRISE_ARCHITECTURE: 'enterprise-architecture',
  ENTERPRISE_CMS: 'enterprise-cms',
  ENTERPRISE_COMMERCE: 'enterprise-commerce',
  SELF_SERVICE_SOLUTIONS: 'self-service-solution',
  NONE: 'none',
};

module.exports = PageTags;
