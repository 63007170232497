/* eslint-disable max-classes-per-file */
export const ConfigVariable = {
  ContentfulSpaceId: 'ContentfulSpaceId',
  ContentfulAccessTokenContent: 'ContentfulAccessTokenContent',
  ContentfulAccessTokenPreview: 'ContentfulAccessTokenPreview',
  ContentfulEnvironments: 'ContentfulEnvironments',
  BuildVersion: 'BuildVersion',
  EditorToolsEnabled: 'EditorToolsEnabled',
  ApplicationInsightsConnectionString: 'ApplicationInsightsConnectionString',
  ApplicationInsightsInstrumentationKey:
    'ApplicationInsightsInstrumentationKey',
  NodeEnv: 'NodeEnv',
  MailSenderUsername: 'MailSenderUsername',
  MailSenderPassword: 'MailSenderPassword',
  MailSmtpHost: 'MailSmtpHost',
  MailSmtpPort: 'MailSmtpPort',
  MailSendToAddress: 'MailSendToAddress',
  MailSendFromAddress: 'MailSendFromAddress',
  ImageImgixPrefix: 'ImageImgixPrefix',
  ContentfulImageImgixPrefix: 'ContentfulImageImgixPrefix',
  GoogleAnalytics4Key: 'GoogleAnalytics4Key',
  GoogleTagManagerKey: 'GoogleTagManagerKey',
  GoogleTagManagerExtension: 'GoogleTagManagerExtension',
  CacheTimeToLiveInSecs: 'CacheTimeToLiveInSecs',
  AzureAccessToken: 'AzureAccessToken',
  AzureStorageAccount: 'AzureStorageAccount',
  PlausibleTrackLocalhost: 'PlausibleTrackLocalhost',
};

class Variable {
  name: string;

  isReadOnly: boolean;

  isRequired: boolean;

  valueIsSet: boolean;

  value;

  constructor(name, isRequired, isReadOnly) {
    this.name = name;
    this.isReadOnly = isReadOnly;
    this.isRequired = isRequired;
    this.valueIsSet = false;
  }

  set(value) {
    if (this.isReadOnly && this.isSet()) {
      throw new Error(
        `Tried to set read-only config variable '${this.name}' a second time`,
      );
    }
    this.value = value;
    this.valueIsSet = true;
  }

  isSet() {
    return this.valueIsSet;
  }

  get() {
    if (this.isRequired && !this.isSet()) {
      throw new Error(`Tried to retrieve unset config variable '${this.name}'`);
    }
    return this.value;
  }

  hasName(name: string) {
    return this.name === name;
  }
}

export class ConfigBase {
  variables: Variable[];

  constructor(variables: Variable[]) {
    this.variables = variables;
  }

  get(variableName: string) {
    const variable = this.variables.find((variable) =>
      variable.hasName(variableName),
    );
    if (variable) {
      return variable.get();
    }
    throw new Error(
      `Tried to retrieve non-existing config variable '${variableName}'`,
    );
  }

  set(variableName: string, value) {
    const variable = this.variables.find((variable) =>
      variable.hasName(variableName),
    );
    if (!variable) {
      throw new Error(
        `Tried to set non defined config variable '${variableName}'`,
      );
    }
    variable.set(value);
  }

  has(variableName: string) {
    const variable = this.variables.find((variable) =>
      variable.hasName(variableName),
    );
    return !!variable;
  }

  isSet(variableName: string) {
    const variable = this.variables.find((variable) =>
      variable.hasName(variableName),
    );
    return !!variable && variable.isSet();
  }
}

const genClientVariables = () => [
  new Variable(
    ConfigVariable.ApplicationInsightsInstrumentationKey,
    false,
    true,
  ),
  new Variable(ConfigVariable.BuildVersion, false, true),
  new Variable(ConfigVariable.ContentfulAccessTokenContent, true, true),
  new Variable(ConfigVariable.ContentfulEnvironments, true, true),
  new Variable(ConfigVariable.ContentfulAccessTokenPreview, false, true),
  new Variable(ConfigVariable.EditorToolsEnabled, true, false),
  new Variable(ConfigVariable.ContentfulSpaceId, true, true),
  new Variable(ConfigVariable.ImageImgixPrefix, true, true),
  new Variable(ConfigVariable.ContentfulImageImgixPrefix, true, true),
  new Variable(ConfigVariable.CacheTimeToLiveInSecs, true, true),
  new Variable(ConfigVariable.PlausibleTrackLocalhost, false, true),
  new Variable(ConfigVariable.AzureStorageAccount, true, true),
];

const genServerVariables = () => [
  new Variable(ConfigVariable.ApplicationInsightsConnectionString, false, true),
  new Variable(
    ConfigVariable.ApplicationInsightsInstrumentationKey,
    false,
    true,
  ),
  new Variable(ConfigVariable.BuildVersion, false, true),
  new Variable(ConfigVariable.ContentfulAccessTokenContent, true, true),
  new Variable(ConfigVariable.ContentfulAccessTokenPreview, false, true),
  new Variable(ConfigVariable.ContentfulEnvironments, true, true),
  new Variable(ConfigVariable.ContentfulSpaceId, true, true),
  new Variable(ConfigVariable.EditorToolsEnabled, true, false),
  new Variable(ConfigVariable.NodeEnv, true, true),
  new Variable(ConfigVariable.MailSenderPassword, false, true),
  new Variable(ConfigVariable.MailSenderUsername, false, true),
  new Variable(ConfigVariable.MailSmtpHost, false, true),
  new Variable(ConfigVariable.MailSmtpPort, false, true),
  new Variable(ConfigVariable.MailSendToAddress, false, true),
  new Variable(ConfigVariable.MailSendFromAddress, false, true),
  new Variable(ConfigVariable.ImageImgixPrefix, true, true),
  new Variable(ConfigVariable.ContentfulImageImgixPrefix, true, true),
  new Variable(ConfigVariable.GoogleAnalytics4Key, false, true),
  new Variable(ConfigVariable.GoogleTagManagerKey, false, true),
  new Variable(ConfigVariable.GoogleTagManagerExtension, false, true),
  new Variable(ConfigVariable.CacheTimeToLiveInSecs, true, true),
  new Variable(ConfigVariable.AzureAccessToken, true, true),
  new Variable(ConfigVariable.AzureStorageAccount, true, true),
  new Variable(ConfigVariable.PlausibleTrackLocalhost, false, true),
];

export class ServerConfig extends ConfigBase {
  constructor() {
    super(genServerVariables());

    this.initialize();
    const unsetButRequired = this.variables.filter(
      (variable) => variable.isRequired && !variable.isSet(),
    );
    if (unsetButRequired.length > 0) {
      throw Error(
        `Not all required variables were set upon initialization. Unset variables were: ${unsetButRequired
          .map((variable) => variable.name)
          .join(', ')}`,
      );
    }
  }

  initialize() {
    const matchToEnv = {
      [ConfigVariable.BuildVersion]: process.env.RAZZLE_BUILD_VERSION, // let this one be "burned" into the codebase during compilation
      [ConfigVariable.ApplicationInsightsConnectionString]:
        process.env.RAZZLE_APPLICATION_INSIGHTS_CONNECTION_STRING,
      [ConfigVariable.ApplicationInsightsInstrumentationKey]:
        process.env.RAZZLE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
      [ConfigVariable.ContentfulAccessTokenContent]:
        process.env.RAZZLE_CONTENTFUL_ACCESS_TOKEN_CONTENT,
      [ConfigVariable.ContentfulAccessTokenPreview]:
        process.env.RAZZLE_CONTENTFUL_ACCESS_TOKEN_PREVIEW,
      [ConfigVariable.ContentfulSpaceId]:
        process.env.RAZZLE_CONTENTFUL_SPACE_ID,
      [ConfigVariable.GoogleAnalytics4Key]:
        process.env.RAZZLE_GOOGLE_ANALYTICS_4_KEY,
      [ConfigVariable.GoogleTagManagerExtension]:
        process.env.RAZZLE_GOOGLE_TAG_MANAGER_EXTENSION,
      [ConfigVariable.GoogleTagManagerKey]:
        process.env.RAZZLE_GOOGLE_TAG_MANAGER_KEY,
      [ConfigVariable.NodeEnv]: process.env.NODE_ENV,
      [ConfigVariable.MailSendFromAddress]:
        process.env.RAZZLE_MAIL_SEND_FROM_ADDRESS,
      [ConfigVariable.MailSendToAddress]:
        process.env.RAZZLE_MAIL_SEND_TO_ADDRESS,
      [ConfigVariable.MailSenderPassword]:
        process.env.RAZZLE_MAIL_SENDER_PASSWORD,
      [ConfigVariable.MailSenderUsername]:
        process.env.RAZZLE_MAIL_SENDER_USERNAME,
      [ConfigVariable.MailSmtpHost]: process.env.RAZZLE_MAIL_SMTP_HOST,
      [ConfigVariable.ImageImgixPrefix]: process.env.RAZZLE_IMAGE_IMGIX_PREFIX,
      [ConfigVariable.ContentfulImageImgixPrefix]:
        process.env.RAZZLE_CONTENTFUL_IMAGE_IMGIX_PREFIX ?? 'novaweb.imgix.net',
      [ConfigVariable.CacheTimeToLiveInSecs]:
        process.env.RAZZLE_CACHE_TIME_TO_LIVE_IN_SECS,
      [ConfigVariable.AzureAccessToken]: process.env.RAZZLE_AZURE_ACCESS_TOKEN,
      [ConfigVariable.AzureStorageAccount]:
        process.env.RAZZLE_AZURE_STORAGE_ACCOUNT,
      [ConfigVariable.PlausibleTrackLocalhost]:
        process.env.RAZZLE_PLAUSIBLE_TRACK_LOCALHOST,
    };
    Object.keys(matchToEnv)
      .filter((key) => matchToEnv[key])
      .forEach((key) => this.set(key, matchToEnv[key]), this);

    let environments = ['master', 'development'];
    if (process.env.RAZZLE_CONTENTFUL_ENVIRONMENTS) {
      environments = process.env.RAZZLE_CONTENTFUL_ENVIRONMENTS.split(',');
    }
    this.set(ConfigVariable.ContentfulEnvironments, environments);
    this.set(
      ConfigVariable.EditorToolsEnabled,
      String(process.env.RAZZLE_EDITOR_TOOLS_ENABLED).toLowerCase() === 'true',
    );
    this.set(
      ConfigVariable.MailSmtpPort,
      process.env.RAZZLE_MAIL_SMTP_PORT &&
        Number(process.env.RAZZLE_MAIL_SMTP_PORT),
    );
  }

  serializeForClient() {
    const clientVars = genClientVariables();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const varsAsObj = clientVars
      // find the intersection between server and client
      .filter((variable) => this.has(variable.name), this)
      // mash them into an object
      .reduce((acc, variable) => {
        acc[variable.name] = self.get(variable.name);
        return acc;
      }, {});
    return JSON.stringify(varsAsObj);
  }
}

export class ClientConfig extends ConfigBase {
  constructor() {
    super(genClientVariables());
  }

  initializeFromSerialized(serializedConfig) {
    let varsAsObj = serializedConfig;
    if (typeof serializedConfig === 'string') {
      varsAsObj = JSON.parse(serializedConfig);
    }
    Object.keys(varsAsObj).forEach(
      (variableName) => this.set(variableName, varsAsObj[variableName]),
      this,
    );
  }
}
