import {
  BodyTextWidthEnum,
  CallToActionTypeEnum,
  CallToActionWithAlignmentTypeEnum,
  HeaderTextGroupTypeEnum,
  HeroTypeEnum,
  KeyFigureGridTypeEnum,
  List3GroupsTypeEnum,
  List3GroupsHeadersEnum,
  PositionEnum,
  ProfileEnum,
  ProfileGridEnum,
  QuoteTypeEnum,
  WellTypeEnum,
  FactBoxEnum,
  PageTagEnum,
  SEOWeightsEnum,
  IsProductPageEnum,
} from '../../constants/cms-constants';
import TitleTypesEnum from '../../constants/title-types';
import {
  ControlOptionDefinition,
  displayTypes,
} from '../../types/ts/controlOptions';

const callToActionTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: CallToActionTypeEnum },
};

const callToActionWithAlignmentTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: CallToActionWithAlignmentTypeEnum },
};

const headerTextGroupTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: HeaderTextGroupTypeEnum },
};

const heroTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: HeroTypeEnum },
};

const keyFigureGridTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: KeyFigureGridTypeEnum },
};

const list3GroupTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: List3GroupsTypeEnum },
};

const list3GroupHeaders: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: List3GroupsHeadersEnum },
};

const pageTags: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: PageTagEnum },
};

const positionTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: PositionEnum },
};

const profileGridTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: ProfileGridEnum },
};

const profileTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: ProfileEnum },
};

const quoteTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: QuoteTypeEnum },
};

const titleTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: TitleTypesEnum },
};

const wellTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: WellTypeEnum },
};

const FactboxTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: FactBoxEnum },
};

const SEOWeightTypes: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: SEOWeightsEnum },
};

const bodyTextWidth: ControlOptionDefinition = {
  displayType: displayTypes.dropdown,
  validation: { enum: BodyTextWidthEnum },
};

const isProductPage: ControlOptionDefinition = {
  displayType: displayTypes.boolean,
  validation: { enum: IsProductPageEnum },
};

const customControlOptionDefinitions = {
  bodyTextWidth,
  callToActionTypes,
  callToActionWithAlignmentTypes,
  headerTextGroupTypes,
  heroTypes,
  keyFigureGridTypes,
  list3GroupTypes,
  list3GroupHeaders,
  positionTypes,
  profileGridTypes,
  profileTypes,
  quoteTypes,
  titleTypes,
  wellTypes,
  pageTags,
  FactboxTypes,
  SEOWeightTypes,
  isProductPage,
};

export default customControlOptionDefinitions;
