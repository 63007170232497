import { ImageFile } from '../../../constants/internal-types';
import { AppConfigType } from '../../../client-server-utils/appConfig';
import ImageHelper from './imageHelper';

class ContentfulImageHelper implements ImageHelper {
  private _image: ImageFile;

  private _appConfig: AppConfigType;

  constructor(image: ImageFile, appConfig: AppConfigType) {
    this._image = image;
    this._appConfig = appConfig;
  }

  isAnimation() {
    return /.json$/.test(this._image.url);
  }

  isSvg() {
    return /.svg$/.test(this._image.url);
  }

  supportsImgix() {
    return !(this.isAnimation() || this.isSvg());
  }

  getImageUrl() {
    return this.isAnimation()
      ? this._image.url
      : this._image.url.replace(
          /novaphotos\.blob\.core\.windows\.net\/.*(?=\/)/,
          this._appConfig.imageImgixPrefix,
        );
  }

  getAssetName() {
    return this._image.assetName;
  }

  getImageSize() {
    if (!this.supportsImgix()) {
      return {
        widthInPx: this._image.widthInPx,
        heightInPx: this._image.heightInPx,
        aspectRatio: this._image.widthInPx / this._image.heightInPx,
      };
    }

    return {};
  }

  getImageSizeInBytes() {
    return this.supportsImgix() ? undefined : this._image.sizeInBytes;
  }

  getRawImage(): ImageFile {
    return this._image;
  }
}

export default ContentfulImageHelper;
