import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import ArticleGridMolecule from './common/molecules/ArticleGrid';
import CallToAction from './common/molecules/CallToAction';
import Header from './common/atoms/Header';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = {
  '&.article-grid-component': {
    [Breakpoints.DesktopUp.mq]: {
      paddingTop: '50px',
      paddingBottom: '128px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingTop: '35px',
      paddingBottom: '105px',
    },
    [Breakpoints.Mobile.mq]: {
      paddingTop: '40px',
      paddingBottom: '105px',
    },
  },
  '& .central': {
    cursor: 'pointer',
    [Breakpoints.DesktopUp.mq]: {
      marginTop: '110px',
    },
    [Breakpoints.TabletDown.mq]: {
      marginTop: '80px',
    },
  },
  '& .grid-header': {
    marginBottom: '60px',
    marginTop: '80px',
    paddingLeft: '8px',
  },
};

type ArticleGridProps = {
  mediaItems?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
  link?: InternalPropTypes.Link;
  header?: InternalPropTypes.RichText;
};

class ArticleGrid extends Component<ArticleGridProps> {
  static defaultProps = {
    mediaItems: null,
    backgroundColor: null,
    link: null,
    header: null,
  };

  render() {
    const { header, mediaItems, backgroundColor, link } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    const className = link
      ? 'article-grid-component'
      : 'article-grid-component page';

    return (
      <div className={className} css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            {header && (
              <Header
                seoWeight="p"
                className="grid-header"
                type={Header.types.HERO}
              >
                {header}
              </Header>
            )}

            {mediaItems && (
              <ArticleGridMolecule items={mediaItems} showAll={!link} />
            )}

            <div
              className="central"
              style={{ display: !link ? 'none' : 'block' }}
            >
              {link && (
                <CallToAction link={link} type={CallToAction.types.CENTRAL} />
              )}
            </div>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ArticleGrid;
